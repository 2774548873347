type DatasetElementEventTarget = EventTarget & Pick<HTMLElement, 'dataset'>;

function isDatasetElementEventTarget(element: EventTarget): element is DatasetElementEventTarget {
  return (element as DatasetElementEventTarget).dataset !== undefined;
}

function isTagElement(element: EventTarget): element is HTMLElement {
  return (element as HTMLElement).tagName !== undefined;
}

interface CdpEventDefaults {
  company_id?: number;
  company_subdomain?: string;
  infuse_employee?: boolean;
}

enum PostHogEventType {
  PageView = '$pageview',
}

/**
 * A quick representation of the Posthog window object
 */
interface PostHog {
  capture(eventName: string, attributes: Record<string, any>): void;
  group(groupName: string, groupIdentifier: string): void;
  people: {
    set(attributes: Record<string, any>): void;
  };
}

type PostHogWindow = Window & { posthog: PostHog; cdpEventDefaults: CdpEventDefaults };

function isPosthogWindow(window: Window): window is PostHogWindow {
  return (window as PostHogWindow).posthog !== undefined;
}

/**
 * Reports a click to the CDP platform
 */
function reportCdpClick(identifier: string) {
  if (isPosthogWindow(window)) {
    const { posthog, cdpEventDefaults } = window;
    if (cdpEventDefaults.company_subdomain) {
      posthog.group('company', cdpEventDefaults.company_subdomain);
    }
    posthog.capture(identifier, cdpEventDefaults);
  }
}

// assign to the window to use it elsewhere
window.reportCdpClick = reportCdpClick;

/**
 * Reports a page to the CDP platform
 */
function reportPageView() {
  if (isPosthogWindow(window)) {
    const { posthog, cdpEventDefaults } = window;
    if (cdpEventDefaults.company_subdomain) {
      posthog.group('company', cdpEventDefaults.company_subdomain);
    }
    posthog.capture(PostHogEventType.PageView, {
      ...cdpEventDefaults,
      page: window.location.pathname.toString(),
    });
  }
}

// assign to the window to use it elsewhere
window.reportPageView = reportPageView;

/**
 * Finds the first `data-cdp-click` value in a bubbled event element path
 * @param path a list of elements from the bubbled click event
 * @returns the `data-cdp-click` attribute of the element
 */
function findFirstCdpClick(path: EventTarget[]): string | undefined {
  let clickableElementInPath = false;

  for (let index = 0; index < path.length; index++) {
    const element = path[index];

    if (!clickableElementInPath && isTagElement(element)) {
      clickableElementInPath = ['A', 'BUTTON'].includes(element.tagName);
    }

    if (clickableElementInPath && isDatasetElementEventTarget(element) && element.dataset.cdpClick) {
      return element.dataset.cdpClick;
    }
  }

  return null;
}

// attach a listener to the document itself and then selectively report clicks
document.addEventListener('click', (event) => {
  try {
    const path = event.composedPath();
    // do any items in our path have a `data-cdp-click` property?
    const cdpClick = findFirstCdpClick(path);
    if (cdpClick) reportCdpClick(cdpClick);
  } catch (error) {
    // lets not complain about this error, as this function is purely for CDP purposes.
  }
});

document.addEventListener('turbolinks:load', () => {
  // do not report pageviews - requested by Freddy
  // reportPageView();
});
